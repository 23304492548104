import { FunctionalComponent, h } from 'preact';
import { InlineCitation as Cite, Citations } from './citations';
import { FAQ } from './FAQ';
import { List, ListItem } from './List';
import { DottedExternalLink, DottedSimpleLink, MiniQuote, Paragraph, Quote, QuoteAuthor } from './typography';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import { setup, styled } from 'goober';

setup(h); // required setup for Goober to work

const App: FunctionalComponent = () => {
    return (
        <MainContainer>
            <Hero backgroundURL={'/assets/img/deer.jpg'} id="top">
                What is species justice?
            </Hero>
            <PlainSection>
                <Quote>“It is impossible to view a living being as a product, a unit of production, as a profit margin, and not engage in the abrogation of their fundamental rights.”</Quote>
                <QuoteAuthor>~ Kristy Alger (Writing Liberation)</QuoteAuthor>
                <Paragraph>
                    Many non-animal industries can be reformed, whether it be by higher wages, greater worker protections, or environmental regulations.
                </Paragraph>
                <Paragraph>
                    But what happens when the "product" in question is an individual?
                </Paragraph>
                <Paragraph>
                    Imagine a "humane" dog farm, in which dogs were raised in an environment with plenty of space and good food before being shipped to slaughter after a few months or years. Most people would object to such an industry. Yet for other animals society has deemed to be food animals, we've been conditioned to disregard our core values of equality, consent, and bodily autonomy.
                </Paragraph>
                <Paragraph>
                    It's time to deconstruct the political notion of animality.
                    {/* It's time for all animals to have a seat at the political table. */}
                </Paragraph>
            </PlainSection>
            <ParallaxSection backgroundURL='/assets/img/cows_edited.jpg' id='humaneparadox'>
                The humane paradox
            </ParallaxSection>
            <PlainSection>
                <Quote>
                    “You can't have your happy animals and eat them too.”
                </Quote>
                <QuoteAuthor>~ David Oates, Animal Justice Project</QuoteAuthor>
                <Paragraph>
                    Some are surprised to find that many common animal welfare certifications are operated directly by the industry. As it turns out, this is an ingenious strategy for these industries for several reasons:
                </Paragraph>
                <Paragraph>
                    The more obvious reason is that this allows the industries to set the bar as low as they want while still placating consumers and reducing the perceived need for external government regulation.
                </Paragraph>
                <Paragraph>
                    A more subtle, but arguably more important, reason is that doing so allows the industry to control the conversation. More specifically, it conveniently reframes the issue to sidestep the question of animal slaughter altogether.
                </Paragraph>
                <Paragraph>
                    {/* Sometimes, we as a society recognize animal welfare as irrelevant. For instance, every few years */}
                    A study by Hahn et al <Cite url={Citations.hahnEtAl_childrenAgentsOfProtection.url} /> showed that the vast majority of children aged 4-7 think chickens (65%), pigs (73%), and cows (77%) are "not OK to eat". Similarly, Wilks et al <Cite url={Citations.wilksEtAl_childrenVsAdultsSacrificeSpecies.url} /> demonstrated that "[children] prioritized [the lives of] 10 pigs over [the life of] one human. By contrast, almost all adults chose to save one human over even 100 dogs or pigs". What this tells us is that species discrimination is a learned, not intrinsic, human ideology.
                </Paragraph>
                <Paragraph>
                    A great example of this learned discrimination is the outrage adults express whenever news surfaces every so often of dead dogs (or cats) being <DottedExternalLink text="found in someone's freezer" url="https://www.theguardian.com/us-news/2022/apr/14/arizona-man-froze-animals-garage-freezer" />. What's notable is that, unlike when a pig or cow is killed, people's first concern is never whether the dogs were given enough room to move around while they were alive, nor whether they were given a certain grade of food by their killer, but rather that they were slaughtered at all.
                </Paragraph>
                <Paragraph>
                    What children and, sometimes, adults are picking up on here is the so-called "humane paradox". If we kill a nonhuman individual who is sickly and suffering, we could at least try to argue that we're putting them out of their misery (although this is rather disturbing if we are the cause of their suffering). But if we kill a nonhuman individual who is happily enjoying their life, this is arguably worse as they would <i>want to continue living that much more.</i> That is, slaughtering a nonhuman individual is not merely a problem for the one day in which we betray their trust as their caretakers. Instead, it is also an ethical issue for every day that they would have continued living that we are depriving them of. It's why even the most idealized animal farm is not comparable to an animal sanctuary.
                </Paragraph>
                <Paragraph>
                    With dogs and cats, we already know this intuitively: it's part of why we get upset when a companion animal in a loving home dies prematurely. What's important to recognize, however, is that other animals like cows, chickens, and fish want to continue living just as much. Thus, an industry centered around breeding these animals for slaughter has no place in a just society, regardless of how profitable or normalized it may be.
                </Paragraph>
            </PlainSection>
            <ParallaxSection backgroundURL='/assets/img/slaughterhouse.avif' id="systems">
                Systems of injustice
            </ParallaxSection>
            <PlainSection>
                <Quote>
                    “The worst thing, worse than the physical danger, is the emotional toll... Pigs down on the kill floor have come up and nuzzled me like a puppy. Two minutes later I had to kill them-beat them to death with a pipe. I can’t care.”
                </Quote>
                <QuoteAuthor>~ Ed Van Winkle, former slaughterhouse worker {/*<Cite url={Citations.dillard_PITS.url} /> page 8*/}</QuoteAuthor>
                {/* <Quote fontSize='1.5em'>“Considerable social engineering has occurred in the last hundred years to promote and increase the consumption of the flesh, eggs and milk of other animals, especially among the relatively more affluent throughout the world. In the United States the deadly theft of land from Indigenous peoples and free-living nonhuman animals made possible the profitable expansion of ranching. These economic gains were made even greater by the oppression of slaughterhouse workers — disproportionately people of colour and immigrants — who were economically coerced into killing ever-growing numbers of cows, pigs, chickens and similarly oppressed other animals. Such monstrous oppression was possible due to oppressors' control of the state apparatus, and it was rationalized and normalised through the use of speciesism, racism, sexism, classism and related ideologies spun and disseminated by capitalist elites. Early in the 20th century powerful business entities seized control of nascent mass media technology, including those companies within the animal-industrial complex who sought to convince the public that consumption of slaughterhouse fare was safe, healthy and essential.”</Quote> */}
                {/* Possibly find quotes from Slaughter Free Cities */}
                <Paragraph>
                    Contrary to what we are sometimes led to believe, animal liberation is not isolated from other justice struggles.
                </Paragraph>

                <Paragraph>
                    For instance, in addition to the unimaginable physical violence inflicted against nonhuman individuals in slaughterhouses, we must also consider the psychological damage we inflict on the workers in these facilities. In addition to having one of the highest physical injury rates <Cite url={Citations.worrall_meatpackingSafety.url} />{/*p. 309*/}, slaughterhouse workers can also experience a form of PTSD known as Perpetration-Induced Traumatic Stress ("PITS") <Cite url={Citations.dillard_PITS.url} />, often leading to an annual turnover rate of <i>over 100%</i> according to the USDA <Cite url={Citations.usda100Turnover.url} />.
                </Paragraph>
                <Paragraph>
                    Hence, according to a 2019 Human Rights Watch report <Cite url={Citations.hrw_mostAbattoirsPOC.url} />, slaughterhouses largely source their labor from vulnerable communities, including people of color (&gt;65%{/*page 15, pdf 21*/}), undocumented immigrants (as high as 25%{/*page 19 / pdf 25*/}), and even state prisons{/*page 19 / pdf 25*/}. Similarly, concentrated animal feeding operations are disproportionately located near communities of color <Cite url={Citations.wingEtAl3_cafosNearPOC.url} /><Cite url={Citations.kravchenkoEtAl_CAFOzipCodes.url} /><Cite url={Citations.wilsonEtAl_CAFOsNearPOCMississippi.url} /><Cite url={Citations.mirabelliEtAl_pigCAFOsNearPOC.url} />{/*https://www.academia.edu/1446269/Race_poverty_political_capacity_and_the_spatial_distribution_of_swine_waste_in_North_Carolina_1982-1997*/}, leading to residents suffering from increased air pollution <Cite url={Citations.essenEtAl_cafoAirContamination.url} />, respiratory illness <Cite url={Citations.bullers_hogFarmRespiratoryIllness.url} /><Cite url={Citations.coleEtAl_hogFarmHealthEffects.url} /><Cite url={Citations.wingEtAl_nCarolinaHealthEffects.url} />, water contamination <Cite url={Citations.huffmanEtAl_cafoGroundwaterContamination.url} /><Cite url={Citations.burkholderEtAl_cafoWaterContamination.url} /><Cite url={Citations.burkholderEtAl2_cafoSpillIntoWater.url} />, mental health issues <Cite url={Citations.bullers_hogFarmRespiratoryIllness.url} /><Cite url={Citations.schiffmanEtAl_cafoMentalHealth.url} />, {/*financial hardship <Cite url={Citations.edwardsEtAl_POCfarmLoss.url} /><Cite url={Citations.durrenbergerEtAl_CAFOinequality.url} />,*/}and elevated blood pressure <Cite url={Citations.wingEtAl2_cafoBloodPressure.url} />.
                    {/* pig farm concentration bad for economic wellbeing https://doi.org/10.17730/humo.55.4.wtn74681u08t882v, https://doi.org/10.1080/027321700405054 */}

                    {/*
                    https://foodispower.org/environmental-and-global/environmental-racism/
                    https://mercyforanimals.org/blog/how-the-meat-industry-thrives-on-environmental/
                    https://ncejn.org/cafos/
                    */}
                </Paragraph>
                <Paragraph>
                    Furthermore, despite being over twice as likely to avoid animal products as their white counterparts <Cite url={Citations.vegetarianResourceGroup_2xBlackVegans.url} />, Morland et al <Cite url={Citations.morlandEtAl_supermarketsIn8PercentOfBlackCommunities.url} /> calculated that “only 8% of Black Americans lived in a [community] with at least one supermarket,” showing a clear disparity in access to plant-based foods. Similarly, only 13 grocery stores exist in the entire Navajo nation <Cite url={Citations.piltchEtAl_navajo13groceries.url} />. This is especially disturbing considering the history of native peoples being forced off their arable farming lands in order to further "the profitable expansion of ranching" <Cite url={Citations.meatsplaining.url} />.

                    {/* This problem also extends to schools, hospitals, and prisons: the USDA, which sets dietary guidelines for such institutions, recommends and even mandates daily serving of dairy to all citizens, despite the vast majority of Black (75%) and Native (79%) Americans not being able to fully digest dairy https://doi.org/10.1093/ajcn/48.4.1142 */}
                    {/* https://doi.org/10.1007/bf02088058 */}
                    {/* https://doi.org/10.1016/s2468-1253(17)30154-1 */}
                    {/* PCRM: https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2608451/ */}
                </Paragraph>
                <Paragraph>
                    Even more fundamentally, contemporary critical race theorists such as Aph Ko and Syl Ko <Cite url={Citations.aphKo_racismAsZoologicalWitchcraft.url} /><Cite url={Citations.aphroism.url} /> have put into focus the racialized history of the human-animal binary. Namely, they argue that white supremacists have used the term "human" not merely as a synonym for <i>homo sapiens</i> but more narrowly as a signifier for the white, cisgender, heterosexual, monied, and oftentimes male "in-group" whilst using "animal" as a catch-all label to degrade anyone who is "other". This theoretical framework provides insight into the results of a psychological study by Costello et al <Cite url={Citations.costelloEtAl_humanAnimalFraming_ImmigrantHumanization.url} />, which demonstrates that closing the human-animal divide by "emphasizing animals as similar to humans" (rather than the other way around) resulted in increased empathy towards immigrants, decreased prejudice, and greater immigrant humanization, even among highly prejudiced people. The authors theorize that the intervention worked by "robbing participants of the ability to dehumanize the outgroup"{/*, targeting the roots of dehumanization,*/} and "[drawing] the outgroup closer to the ingroup by making outgroup members psychologically more human in nature". Bastian et al <Cite url={Citations.bastianEtAl_humanAnimalFramingEssayExperiment.url} /> observed similar anti-racist psychological effects.
                </Paragraph>
                <Paragraph>
                    Building bridges across movements can be daunting, but when done right, it can be a powerful way to fight back against common oppressors.
                </Paragraph>
                <Paragraph>
                    <LiteYouTubeEmbed id='H_ebX07H4wM' title='Christopher Sebastian McJetters: Exploring Connections between Black Liberation &amp; Animal Liberation' />
                </Paragraph>
            </PlainSection>
            <ParallaxSection backgroundURL='/assets/img/rabbit6.jpg' id="consent">
                The importance of consent
            </ParallaxSection>
            <PlainSection>
                <Quote>“If we are serious about social and economic justice... we must expand our view to everyone—especially the weakest among us. There can be no half-justice for the weak, or justice means nothing at all and we live in a world of might-makes-right.”
                </Quote>
                <QuoteAuthor>~ Dr. Bob Torres, "Making A Killing: The Political Economy of Animal Rights"</QuoteAuthor>
                <Paragraph>
                    Nonhuman animals cannot give consent because we don't know their language. Consider horse or elephant riding. Even if someone appears content being ridden, would they be more content if they could be free to go where they please and experience companionship without someone telling them what to do?  This leads to the question of informed consent: if an animal is broken at a young age or used a certain way their entire life, do they even imagine freedom from servitude as a viable option, or is their consent manufactured?
                </Paragraph>
                <Paragraph>
                    Likewise, sometimes hens will peck at those who try to take their eggs, indicating a clear denial of consent. What’s particularly interesting, however, is when hens don’t peck at those trying to take their eggs — is this consent? Or is this merely compliance?  It is unfair to infer consent, especially when considering the <DottedExternalLink text="psychological and physical harm taking hens’ eggs has on their wellbeing" url="https://www.youtube.com/watch?v=7YFz99OT18k" />.
                </Paragraph>
                <Paragraph>
                    Similarly, consider the dairy industry. In order for a cow to produce milk she must be impregnated and give birth to a calf, and in order to continue producing milk she must be forcibly reimpregnated and give birth repeatedly <Cite url={Citations.usda_impregnateCows.url} />. The baby calf is stolen from their mother and killed if male or forced into servitude if female. We know that mother cows often cry out for their babies for days <Cite url={Citations.news_dairyCowsCryOut.url} />, but when they don’t, can this truly be considered consent?   Or is this merely learned helplessness?
                </Paragraph>
                <Paragraph>
                    A bee, pollinating thousands of flowers a day, will only produce a teaspoon of honey in their entire lifetime. Is it right that we not only <DottedExternalLink text="exploit their labor" url="https://www.youtube.com/watch?v=clMNw_VO1xo" />, but also steal the fruits of said labor for ourselves?
                </Paragraph>
                <Paragraph>
                    Nonhuman individuals resist exploitation every day: they break out of zoo enclosures, jump out of slaughter trucks, and <DottedExternalLink text="try to rescue each other from slaughter lines" url="https://www.youtube.com/watch?v=E60FMCNag94" />. So if we continue exploiting these individuals just because they sometimes comply, or they can't express themselves in our language, what does that say about us as a society?
                </Paragraph>
            </PlainSection>
            <ParallaxSection backgroundURL='/assets/img/forest.jpg' id="environment">
                Our planet, theirs too
            </ParallaxSection>
            <PlainSection>
                <Quote>
                    “Agricultural production and GHG [greenhouse gas] mitigation goals cannot be reached simultaneously.”
                </Quote>
                <QuoteAuthor>
                    ~ U.S. Department of Agriculture, 2012 <Cite url={Citations.usdaSimultaneously.url} />
                </QuoteAuthor>
                <Paragraph>
                    Animal agriculture is responsible for more emissions than the total exhaust from all vehicles combined <Cite url={Citations.fao2006.url} />, and furthermore animal agriculture is the leading cause of species extinction <Cite url={Citations.coimbraEtAl_leadingBiodiversityLoss.url} /><Cite url={Citations.machovinaEtAl_leadingSpeciesExtinction.url} />, deforestation <Cite url={Citations.hendersEtAl_leadingDeforestation.url} />, and habitat destruction <Cite url={Citations.machovinaEtAl_leadingSpeciesExtinction.url} />.
                </Paragraph>
                <Paragraph>
                    Even more concerning, more recent studies including Bajželj et al <Cite url={Citations.bajzeljEtAl_ghg2050_2014.url} />, Springmann et al <Cite url={Citations.springmannEtAl_ghg2050_2018.url} />, and Clark et al <Cite url={Citations.clarkEtAl_ghg2100_2020.url} /> have reached a disturbing consensus: agriculture <i>alone</i> will push us over the 1.5°C (and likely even the 2°C) limit unless we as a society change our diets. What this means is <b>even if tomorrow morning all fossil fuels were eliminated, just continuing our current meat-based diets would prevent us from meeting our climate goals</b>.
                </Paragraph>
                <Paragraph>
                    In contrast, a 5-year study by Poore et al <Cite url={Citations.pooreEtAl_ghg28.url} /> calculated that transitioning to a plant-based food system would result in <i><b>net <u>negative</u> emissions</b></i> in the agricultural sector. This would mean, in addition to eliminating net agricultural emissions, we would be soaking up emissions from fossil fuels and other sectors. Hayek et al <Cite url={Citations.hayekEtAl_sequester163Percent.url} /> calculated that this would significantly improve our chances of limiting warming to 1.5°C, increasing our total carbon budget by 163%.
                </Paragraph>
                <Paragraph>
                    {/*Animals eat more food than they produce*/}
                    These negative emissions are possible due to the inefficiency of filtering plant nutrients and proteins through other animals. Shepon et al <Cite url={Citations.sheponEtAl_feedConversions.url} /> calculated that on average, 93% of the calories that farmed animals eat are dissipated and do not end up in the final animal products. This applies even to "grass-fed" and "free-range" farms: not only are they not scalable <Cite url={Citations.hayekEtAl_ghg15deg_grassFedBeefScale27.url} />, studies <Cite url={Citations.grassFed67Higher.url} /><Cite url={Citations.grassFed30Higher.url} /><Cite url={Citations.grassFed37Higher.url} /> show <b>"free range" animals emit significantly <i>more</i> emissions than "regular" factory farmed animals.</b> Ultimately, animal products use "~83% of the world’s farmland [...] despite providing only 37% of our protein and 18% of our calories" <Cite url={Citations.pooreEtAl_ghg28.url} />. Adopting a plant-based food system would thus shrink our agricultural land use by 75% <Cite url={Citations.pooreEtAl_ghg28.url} />{/* calculated from Poore here: https://ourworldindata.org/land-use-diets#more-plant-based-diets-tend-to-need-less-cropland */}, allowing much of that land to rewild and absorb carbon.

                    {/* In contrast, transitioning to a plant-based food system would not only drastically cut down food-related emissions, but it would also <b>dramatically increase sequestration of emissions from fossil fuels</b>. This is because of the opportunity cost of land use: "While livestock takes up most of the world’s agricultural land it only produces 18% of the world’s calories and 37% of total protein" <Citation url={Citations.landPerCalories.url} />. What this means is a transition to a plant-based food system would free up  */}
                </Paragraph>
                <Paragraph>
                    <DottedExternalLink text="See here" url="/assets/environment.pdf" /> for additional detail and FAQs.
                </Paragraph>
                {/* <Paragraph>
                    Many are already aware of the 2006 UN FAO study <Citation url={Citations.fao2006.url} /> which showed that animal agriculture was was responsible for more emissions than the total exhaust from all vehicles combined.
                </Paragraph>
                <Paragraph>
                    However, more recent studies have shown that animal agriculture is responsible for even larger <i>opportunity costs</i> in carbon-sinking forest land. This is due to the massive waste in filtering plant nutrients through other animals: animal agriculture occupies 77% of global farming land while only providing 18% of the world's calories and 37% of the world's protein <Citation url={Citations.landPerCalories.url} />.
                </Paragraph>
                <Paragraph>
                    Hence, transitioning to a plant-based food system would allow massive amounts of land to return to its pre-industrial state (e.g. forests), reducing net emissions by at least 28% <Citation url={Citations.ghg28.url} />, with the possibility of even higher reduction over time <Citation url={Citations.ghg87.url} />.
                </Paragraph> */}
            </PlainSection>
            <ParallaxSection backgroundURL='/assets/img/ecomarch.jpg' id="progressives">
                Take action
            </ParallaxSection>
            <PlainSection>
                <Quote>
                    “There is no such thing as a single-issue struggle because we do not live single-issue lives.”
                </Quote>
                <QuoteAuthor>
                    ~ Audre Lorde
                </QuoteAuthor>
                <Paragraph>
                    Animal oppression is embedded into the structure of our society. To fight it, we'll need to change the system, via pressure campaigning, direct action, political organization, solidarity work, and other forms of activism.
                    {/* TODO: examples of current campaigns to join */}
                </Paragraph>
                <Paragraph>
                    However, it's also important to address the elephant in the room: the speciesism within ourselves.
                </Paragraph>
                <Paragraph>
                    The problem with individual action from a solely <i>economic</i> perspective is that it's not enough just on its own: <i>if</i> saving nonhuman individuals and the climate from destruction required convincing 100% of the population to stop consuming animal products within the next few years, then the future might seem bleak considering we can't even seem to convince 100% of the population that, say, climate change is real or trans people deserve basic rights.
                </Paragraph>
                <Paragraph>
                    <b><i>However,</i></b> if we instead look through the lens of building a <i>movement</i> capable of destroying these industries, the story is very different. The way we act influences the way we think <Cite url={Citations.bem_selfPerceptionTheory.url} />, and every time we objectify animals with our actions, whether it be by referring to nonhuman individuals as "it", using speciesist idioms, or using animal comparisons as an insult, we reinforce the speciesist conditioning that we have internalized.
                </Paragraph>
                <Paragraph>
                    Furthermore, when we eat, wear, and ride nonhuman individuals, we develop a conflict of interest in which we are invested in the status quo. Monteiro et al <Cite url={Citations.carnismInventory.url} /> demonstrated that animal consumption is associated with higher rates of <i>carnistic defense</i>, in which a person defends the institution of animal slaughter. This is consistent with previous work by Azevedo et al <Cite url={Citations.systemJustificationAzevedo.url} /> which shows that "people are motivated to defend, bolster, and justify aspects of the societal status quo as something that is familiar and known".
                </Paragraph>
                <Paragraph>
                    One of the most revealing studies on this effect was Loughnan et al <Cite url={Citations.loughnanEtAl_randomAssignFoodAffectsConcern.url} />, in which participants were randomly assigned to eat either nuts or dried beef. Afterwards, participants who had eaten beef reported less moral concern for cows as well as a smaller circle of animals which they considered deserving of moral concern. Even more concerning, Bratanova et al <Cite url={Citations.bratanovaEtAl_edibleReducesPercievedSuffering.url} /> showed that when groups of participants were told about an exotic species of kangaroo, merely describing the kangaroo as edible "was sufficient to reduce the animal's perceived capacity to suffer, which in turn restricted moral concern". What this suggests is that merely perceiving animals as food, even if we don't eat them, de-individualizes them in our minds and hence is a important factor in their objectification and commodification. Bilewicz et al <Cite url={Citations.bilewiczEtAl_edibleFaceRecognitionBrainActivity.url} /> tested this by measuring brain waves of people looking at pictures of a fictional animal species and found that merely mentioning that the animal was edible caused certain participants to have less facial-recognition activity in the brain, further demonstrating the de-individualizing effect of perceiving animals as food.

                    {/* Baratnova et al published a study in which they presented a group of participants with a description of a species of kangaroo in Papua New Guinea, telling half the participants that the kangaroo was edible. What they found is that "categorization as food [alone] was sufficient to reduce the animal's perceived capacity to suffer, which in turn restricted moral concern".  */}
                </Paragraph>
                <Paragraph>
                    By practicing both systemic <i>and</i> individual anti-speciesism, the animal rights movement has experienced exciting success kicking industries such the fur industry down to their last legs. But in order to save the climate and topple targets as large as the animal agriculture industry, nonhuman individuals are going to need a lot more allies.
                </Paragraph>


                <MiniQuote>
                    “Who, if not you? When, if not now?”
                </MiniQuote>
                <QuoteAuthor>
                    ~ Ronnie Lee
                </QuoteAuthor>
            </PlainSection>
            <ParallaxSection backgroundURL='/assets/img/shep.jpg' id="faq">
                Frequently asked questions
            </ParallaxSection>
            <PlainSection>
                <Paragraph>
                    <i>For questions or contributions, please email <DottedExternalLink url="mailto:speciesjustice@disroot.org" text="speciesjustice@disroot.org" /></i>
                </Paragraph>
                <List items={FAQ} />
            </PlainSection>
        </MainContainer >
    );
};



const MainContainer = styled("div")`
    display: flex;
    flex-direction: column;
    background-color: black;
`





const ParallaxSection = styled("section") <{ backgroundURL: string }>`
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    box-sizing: border-box;
    font-size: 6rem;
    background-image: ${props => `url(${props.backgroundURL})`};
    text-align: center;
    font-size: 9vmin;
    color: white;
    text-shadow: 0.05em 0.05em black;
    padding: 0.5em;
    font-weight: bolder;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
`

const Hero = styled(ParallaxSection)`
    min-height: 75vh;
    font-size: 13vmin;
`;



const PlainSection = styled("section")`
    color: #e6e3e3;
    display: grid;
    grid-template-columns: minmax(0.5em, 1fr) minmax(auto, 125ch) minmax(0.5em, 1fr);
    & > * {
        grid-column: 2;
        box-sizing: border-box;
    }
    padding: 1em;
`



export default App;
