import { Fragment, FunctionalComponent, h } from "preact";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import { InlineCitation, Citations, Bibliography } from "./citations";
import { ListItem } from "./List";
import { DottedExternalLink, DottedSimpleLink, Hidden, MicroHeader, MiniHeader, MiniQuote, Paragraph, Quote, QuoteAuthor, SimpleLink, SimpleList, SquareListItem } from "./typography";

const UnsplashLicenseLink: FunctionalComponent = () => <DottedExternalLink text="Unsplash License" url="https://unsplash.com/license" />

export const FAQ: Array<ListItem> = [
    {
        title: "Where can I learn more?",
        body: <Fragment>
            <Paragraph>
            <MicroHeader>Lectures</MicroHeader>
                by Christopher-Sebastian McJetters{/*}, Columbia University Department of Social Work*/}
                <SimpleList>
                    <SquareListItem>
                        <DottedExternalLink text="Exploring Connections between Black Liberation &amp; Animal Liberation" url="https://www.youtube.com/watch?v=H_ebX07H4wM" /> (22:21)
                    </SquareListItem>
                    <SquareListItem>
                        <DottedExternalLink text="Queering Animal Liberation: Why Animal Rights is a Queer Issue" url="https://www.youtube.com/watch?v=SkRke88QKPs" /> (39:46)
                    </SquareListItem>
                    <SquareListItem>
                        <DottedExternalLink text="White Meat: Eating Animals and the Alt Right" url="https://www.youtube.com/watch?v=PvHMEAy2A7o" /> (45:50 + questions)
                    </SquareListItem>
                </SimpleList>


                <MicroHeader>Documentaries</MicroHeader>
                <SimpleList>
                    <SquareListItem>
                        <DottedExternalLink text="Dominion" url="https://www.dominionmovement.com/" /> (free on <DottedExternalLink url="https://archive.org/details/dominion-2018_202203" text="archive.org" />)
                        <i> ~ an exposé of the realities of "humane farming" and the inherent injustice of animal exploitation</i>
                    </SquareListItem>
                    <SquareListItem>
                        <DottedExternalLink text="Test Subjects" url="https://lockwoodfilm.com/test-subjects" /> (free on <DottedExternalLink text="Vimeo" url="https://vimeo.com/359497969" />)
                        <i> ~ a short film documenting the effects of animal testing on doctoral students</i>
                    </SquareListItem>
                    {/* <SquareListItem>
                        <DottedExternalLink text="They're Trying to Kill Us" url="https://www.theyretryingtokillus.com/" /> (coming soon)
                        <i> ~ documents the effects of environmental racism, how black people are systemically denied access to plant foods, and what can be done about it</i>
                    </SquareListItem> */}
                </SimpleList>

                <MicroHeader>Quick explainers</MicroHeader>
                <SimpleList>
                    <SquareListItem>
                        <DottedExternalLink text="What's wrong with backyard eggs?" url="https://www.youtube.com/watch?v=7YFz99OT18k" /> (8:13)
                    </SquareListItem>
                    <SquareListItem>
                        <DottedExternalLink text="What about fish?" url="https://www.youtube.com/watch?v=y8Nj1-YZDlc" /> (6:29)
                    </SquareListItem>
                    <SquareListItem>
                        <DottedExternalLink text="What about dairy?" url="https://www.youtube.com/watch?v=UcN7SGGoCNI" /> (5:40)
                    </SquareListItem>
                    <SquareListItem>
                        <DottedExternalLink text="What about honey?" url="https://www.youtube.com/watch?v=clMNw_VO1xo" /> (6:20)
                    </SquareListItem>
                    <SquareListItem>
                        <DottedExternalLink text="What about wool?" url="https://www.youtube.com/watch?v=siTvjWE2aVw" /> (14:16)
                    </SquareListItem>

                </SimpleList>


                <MicroHeader>Books</MicroHeader>
                <SimpleList>
                    <SquareListItem>
                        <DottedExternalLink text="Racism as Zoological Witchcraft: A Guide to Getting Out" url="https://isbnsearch.org/isbn/1590565967" /> by Aph Ko
                    </SquareListItem>
                    <SquareListItem>
                        <DottedExternalLink text="Aphro-ism: Essays on Pop Culture, Feminism, and Black Veganism from Two Sisters" url="https://isbnsearch.org/isbn/9781590565551" /> by Aph Ko and Syl Ko
                    </SquareListItem>
                    <SquareListItem>
                        <DottedExternalLink text="The Sexual Politics of Meat: A Feminist-Vegetarian Critical Theory" url="https://isbnsearch.org/isbn/9781501312830" /> by Carol J. Adams
                    </SquareListItem>
                    <SquareListItem>
                        <DottedExternalLink text="Ecofeminism, Feminist Intersections with Other Animals and the Earth" url="https://www.google.com/books/edition/Ecofeminism_Second_Edition/d8ROEAAAQBAJ?hl=en&kptab=getbook" /> by Adams and Gruen
                    </SquareListItem>
                </SimpleList>

                <MicroHeader>Other</MicroHeader>
                <SimpleList>
                    <SquareListItem>
                        <DottedExternalLink text="Carnage: Swallowing the Past" url="https://archive.org/details/SimonAmstellCarnageP04sh6zgIplayer" /> by Simon Amstell
                        <i> ~ a more lighthearted film set in 2067 after the end of animal agriculture</i>
                    </SquareListItem>
                    <SquareListItem>
                        <DottedExternalLink text="Helpful organizations" url="assets/organizations.pdf" />
                        <i> ~ a list of organizations that help promote plant-based foods</i>
                    </SquareListItem>
                </SimpleList>
            </Paragraph>
        </Fragment>
    },
    {
        title: "What about hunting for survival?",
        body: <Fragment>
            <MiniQuote>As Craig Womack, an Indigenous scholar who has renounced hunting and consuming animals has observed in recalling the last time he shot a deer and watched her die, ‘there is no way to escape the fundamental inequity of the relationship. I would go as far as to say the lack of relationship: she’s dead, we’re not.’</MiniQuote>
            <QuoteAuthor>~ Maneesha Deckha <InlineCitation url={Citations.deckha_huntingQuote.url} /></QuoteAuthor>
            <Paragraph>
                First, it's important to talk about the demographics of today's hunters:
            </Paragraph>
            <Paragraph>
                “Hunting in the United States generates as little as $23 billion annually or as much as <DottedSimpleLink href="https://www.fs.fed.us/biology/resources/pubs/wildlife/HuntingEconomicImpacts-NSSF-Southwick.pdf" target="_blank" rel="noopener noreferrer">$38.3 billion</DottedSimpleLink>, according to 2015 statistics from the NSSF (the Firearms Industry Trade Association). Regardless of what number you use, that’s a lot of money. In fact, it averages out to $2,800 per hunter. All things considered, people living in such financially disparate conditions don’t enjoy the economic mobility to make this such a profitable industry. In fact, the aforementioned USFWS reporting indicates that statistically zero households earning less than $39,999 per year engage in hunting. So although some people may indeed eat the corpses of their victims, it’s unlikely to be out of necessity. [...] According to statistics compiled by the <DottedSimpleLink href="https://www.census.gov/content/dam/Census/library/publications/2018/demo/fhw16-nat.pdf" target="_blank" rel="noopener noreferrer">U.S. Fish and Wildlife Service</DottedSimpleLink> in 2016, up to 97% of U.S. American hunters are white, and the percentages of other racial groups are so small that they were deemed statistically insignificant.”
            </Paragraph>
            <Paragraph style={{ padding: "0ch 2ch" }}>
                <i>
                    ~ Christopher Sebastian, “<DottedSimpleLink href="https://www.christophersebastian.info/post/until-the-lions-have-their-own-historians-the-objectification-of-black-and-animal-bodies-in-hunting" target="_blank" rel="noopener noreferrer">Until the lions have their own historians: the objectification of black and animal bodies in hunting</DottedSimpleLink>”
                    (CW: racist violence, animal violence)
                </i>
            </Paragraph>
            <Paragraph>
                Furthermore, the population of "game" animals is often artificial. State governments often funnel millions in dollars from hunting licenses into "game farms", in which animals such as deer and pheasants are raised in captivity before being released into the wild for hunters to slaughter <InlineCitation url={Citations.farmRaisedDeer.url} />. Deer populations are also kept artificially high by clearcutting forests <InlineCitation url={Citations.clearcuttingGuide.url} /><InlineCitation url={Citations.clearcuttingEffects.url} />.
            </Paragraph>
            <Paragraph>
                Ultimately, what is the humane way to kill a non-human individual who <i>does not</i> want to die? If someone truly did not have access to food without hunting, then that would mean we failed them as a society and we would need to improve the way we distribute food.
            </Paragraph>
        </Fragment>
    },
    // {
    //     title: "Is a plant-based food system nutritionally adequate?",
    //     body: <Fragment>
    //         <MiniQuote>“[A]ppropriately planned vegetarian, including vegan, diets are healthful, nutritionally adequate, and may provide health benefits for the prevention and treatment of certain diseases. These diets are appropriate for all stages of the life cycle, including pregnancy, lactation, infancy, childhood, adolescence, older adulthood, and for athletes”</MiniQuote>
    //         <QuoteAuthor>Position of the Academy of Nutrition and Dietetics <InlineCitation url={Citations.academyOfNutritionAndDietics.url} /></QuoteAuthor>
    //     </Fragment>
    // },
    // {
    //     title: "What about eggs?",
    //     body: <Fragment>
    //         <Paragraph>
    //             {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/7YFz99OT18k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
    //             <LiteYouTubeEmbed id="7YFz99OT18k" title="Are backyard eggs ethical?" />
    //         </Paragraph>
    //     </Fragment>
    // },
    // {
    //     title: "What about animal testing?",
    //     body: <Fragment>
    //         <MiniQuote>
    //             “We have moved away from studying human disease in humans. We all drank the Kool-Aid on that one, me included. … The problem is that [testing on animals] hasn’t worked, and it’s time we stopped dancing around the problem. … We need to refocus and adapt new methodologies for use in humans to understand disease biology in humans.”</MiniQuote>
    //         <QuoteAuthor>~ Dr. Elias Zerhouni, former director of the National Institutes of Health</QuoteAuthor>
    //         <Paragraph>
    //             {/* Vimeo embed code */}
    //             <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/359497969?h=6bac633add" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen /></div>
    //         </Paragraph>
    //     </Fragment>
    // },

    {
        title: "References",
        body: <Fragment>
            <Paragraph>
                <MiniHeader>Cited Literature</MiniHeader>

                {/* Sources used only in flyers */}
                <Hidden>
                    <InlineCitation url={Citations.imfeld2011_pigsAnticipation.url} />
                    <InlineCitation url={Citations.joy_carnismTED.url} />
                    <InlineCitation url={Citations.marino2017_chickensOverview.url} />
                    <InlineCitation url={Citations.reimert2014_pigsSocialContagion.url} />
                    <InlineCitation url={Citations.souza2006_pigsSocialRecognition.url} />
                    <InlineCitation url={Citations.cassidy_feedExtra4Billion.url} />
                    <InlineCitation url={Citations.springmann2021_cheapDiets.url} />
                </Hidden>

                <Bibliography />

                <MiniHeader>Image Sources</MiniHeader>
                <SimpleList>
                    <SquareListItem>
                        <DottedExternalLink text="Red deer silhouette in the sunrise" url="https://unsplash.com/photos/d5l17dc_lxU" /> by Diana Parkhouse under <UnsplashLicenseLink />
                    </SquareListItem>
                    <SquareListItem>
                        <DottedExternalLink text="Cows" url="https://unsplash.com/photos/UdhpcfImQ9Y" /> by Stijn te Strake, edited under <UnsplashLicenseLink />
                    </SquareListItem>
                    <SquareListItem>
                        <DottedExternalLink text="Stalls (grey metal railings inside building)" url="https://unsplash.com/photos/ZB8ZBwoQ9-8" /> by Erik Mclean under <UnsplashLicenseLink />
                    </SquareListItem>
                    <SquareListItem>
                        <DottedExternalLink text="Aerial view of green leafed trees" url="https://unsplash.com/photos/01Qqkfz-ck8" /> by Noah Silliman under <UnsplashLicenseLink />
                    </SquareListItem>
                    <SquareListItem>
                        <DottedExternalLink text="Aerial Photo of Rise for Climate march in San Francisco" url="https://www.flickr.com/photos/350org/43651207495" /> by Anesti Vega under <DottedExternalLink text="CC BY-NC-SA 2.0" url="https://creativecommons.org/licenses/by-nc-sa/2.0/" />
                    </SquareListItem>
                    <SquareListItem>
                        <DottedExternalLink text="White sheep on green grass during daytime" url="https://unsplash.com/photos/GHOiyov2TSQ" /> by Sam Carter under <UnsplashLicenseLink />
                    </SquareListItem>
                </SimpleList>
            </Paragraph>
        </Fragment>
    }
    // {
    //     title: 'Why does this site not use the word "vegan"?',
    //     body: <Fragment>
    //         <Paragraph>
    //             <DottedSimpleLink href="https://medium.com/@jonmo/bridging-the-gap-e0617d282e77" target="_blank" rel="noopener noreferrer">
    //                 See here.
    //             </DottedSimpleLink>
    //         </Paragraph>
    //     </Fragment>
    // }
]

// fish
// Fish was the third top product sold in the United States at risk of modern slavery (behind electronics and garments). United States imports nearly 90 percent of its seafood https://www.fisheries.noaa.gov/topic/international-affairs#iuu-fishing
// 11 percent of total U.S. seafood imports were derived from illegal, unreported, and unregulated fishing, according to 2021 report by the U.S. International Trade Commission https://www.usitc.gov/press_room/news_release/2021/er0318ll1740.htm
// one in five seafood products tested at U.S. grocery stores, restaurants, and sushi bars were mislabeled and swapped for a different fish species  https://usa.oceana.org/reports/casting-wider-net-more-action-needed-stop-seafood-fraud-united-states/ (2019)
// “None of the retailers surveyed received a passing score” according to a Greenpeace report on human rights issues tied to tuna sold in the United States in 2021. https://www.greenpeace.org/usa/reports/2021-tuna-retailer-scorecard-the-high-cost-of-cheap-tuna/