/* eslint-disable @typescript-eslint/camelcase */
import { styled } from "goober";
import { Fragment, FunctionComponent, h } from "preact"
import { DimSimpleLink, DottedExternalLink, DottedSimpleLink, SimpleLink } from "./typography"

interface Citation {
    url: string;
    full: Node;
}

const DOILink = (props: { doi: string }) =>
    <DottedSimpleLink href={`https://doi.org/${props.doi}`} target="_blank" rel="noopener noreferrer">{props.doi}</DottedSimpleLink>


export const Citations = {
    fao2006: { url: "http://www.fao.org/docrep/010/a0701e/a0701e00.HTM", full: <Fragment>H. Steinfeld et al., <i>Livestock’s long shadow: environmental issues and options.</i> Food &amp; Agriculture Org., 2006.</Fragment> },
    //landPerCalories: { url: "https://ourworldindata.org/global-land-for-agriculture", full: "" },
    carnismInventory: { url: "https://doi.org/10.1016/j.appet.2017.02.011", full: <Fragment>C. A. Monteiro, T. M. Pfeiler, M. D. Patterson, and M. A. Milburn, “The Carnism Inventory: Measuring the ideology of eating animals,” <i>Appetite</i>, vol. 113, pp. 51–62, Jun. 2017, doi: <DOILink doi="10.1016/j.appet.2017.02.011" />.</Fragment> },
    farmRaisedDeer: { url: "https://datcp.wi.gov/Pages/Programs_Services/FarmRaisedDeer.aspx", full: <Fragment>“DATCP Home Farm-Raised Deer Program,” Wisconsin Department of Agriculture, Trade and Consumer Protection. <DottedExternalLink url="https://datcp.wi.gov/Pages/Programs_Services/FarmRaisedDeer.aspx" /> (accessed Jan. 03, 2022).</Fragment> },
    clearcuttingEffects: { url: "https://www.fs.usda.gov/treesearch/pubs/47325", full: <Fragment>A. D. Stottlemyer, “Long-term impact of clearcutting, deer browsing, and defoliation on stand development in a Pennsylvania mixed-oak forest,” U.S. Department of Agriculture, Northern Research Station, General Technical Report NRS-P-142, 2014.{/* Accessed: Jan. 03, 2022.} [Online]. Available: <DottedExternalLink url="http://www.fs.usda.gov/treesearch/pubs/47325" />*/}</Fragment> },
    clearcuttingGuide: { url: "https://www.nrcs.usda.gov/Internet/FSE_DOCUMENTS/nrcs142p2_022286.pdf", full: <Fragment>“Managing Your Woodland for White-Tailed Deer.” U.S. Department of Agriculture, Jan. 2003. [Online]. Available: <DottedExternalLink url="https://www.nrcs.usda.gov/Internet/FSE_DOCUMENTS/nrcs142p2_022286.pdf" /></Fragment> },
    usdaSimultaneously: { url: "https://doi.org/10.1890/120058", full: <Fragment>S. J. D. Grosso and M. A. Cavigelli, “Climate stabilization wedges revisited: can agricultural production and greenhouse‐gas reduction goals be accomplished?,” <i>Frontiers in Ecology and the Environment</i>, vol. 10, no. 10, pp. 571–578, Dec. 2012, doi: <DOILink doi="10.1890/120058" />.</Fragment> },
    // ghg87: {url: "https://climatehealers.org/wp-content/uploads/2021/04/JES-Rao.pdf", full: ""},
    pooreEtAl_ghg28: { url: "https://doi.org/10.1126/science.aaq0216", full: <Fragment>J. Poore and T. Nemecek, “Reducing food’s environmental impacts through producers and consumers,” <i>Science</i>, vol. 360, no. 6392, pp. 987–992, Jun. 2018, doi: <DOILink doi="10.1126/science.aaq0216" />.</Fragment> },
    systemJustificationAzevedo: { url: "http://dx.doi.org/10.1037/tps0000127", full: <Fragment>J. T. Jost et al., “Ideology and the limits of self-interest: System justification motivation and conservative advantages in mass politics.,” <i>Translational Issues in Psychological Science</i>, vol. 3, no. 3, pp. e1–e26, Sep. 2017, doi: <DOILink doi="10.1037/tps0000127" />.</Fragment> },
    // speciesExtinction: { url: "https://portals.iucn.org/library/node/9830", full: <Fragment>J. Baillie, C. Hilton-Taylor, and S. N. Stuart, Eds., <i>2004 IUCN red list of threatened species: a global species assessment.</i> Gland, Switzerland: IUCN--The World Conservation Union, 2004.</Fragment> },
    desertification: { url: "http://www.ciesin.columbia.edu/docs/002-186/002-186.html", full: <Fragment>H. E. Dregne and N.-T. Chou, “Global desertification dimensions and costs,” <i>Degradation and restoration of arid lands</i>, vol. 1, pp. 73–92, 1992.</Fragment> },

    // Studies that show GHG mitigation goals cannot be met w/ animal ag
    springmannEtAl_ghg2050_2018: { url: "https://doi.org/10.1038/s41586-018-0594-0", full: <Fragment>M. Springmann et al., “Options for keeping the food system within environmental limits,” <i>Nature</i>, vol. 562, no. 7728, pp. 519–525, Oct. 2018, doi: <DOILink doi="10.1038/s41586-018-0594-0" />.</Fragment>},
    // "GHG emissions cannot be sufficiently mitigated without dietary changes towards more plant-based diets"
    clarkEtAl_ghg2100_2020: { url: "https://doi.org/10.1126/science.aba7357", full: <Fragment>M. A. Clark et al., “Global food system emissions could preclude achieving the 1.5° and 2°C climate change targets,” <i>Science</i>, vol. 370, no. 6517, pp. 705–708, Nov. 2020, doi: <DOILink doi="10.1126/science.aba7357" />.</Fragment> },
    // 1.5°C 50% chance via plant-rich diets
    kimEtAl_pass2Deg_2015: { url: "http://dx.doi.org/10.13140/RG.2.1.3385.7362", full: <Fragment>B. Kim, R. Neff, Raychel Santo, and J. Vigorito, “The Importance of Reducing Animal Product Consumption and Wasted Food in Mitigating Catastrophic Climate Change,” 2015, doi: <DOILink doi="10.13140/RG.2.1.3385.7362" />.</Fragment> },
    // “If global trends in meat and dairy intake continue, global mean temperature rise will more than likely exceed 2°C, even with dramatic emissions reductions across non‐agricultural sectors.”     
    roosEtAl_ghg2050_2016: { url: "https://doi.org/10.1007/s10113-016-1013-4", full: <Fragment>E. Röös, B. Bajželj, P. Smith, M. Patel, D. Little, and T. Garnett, “Protein futures for Western Europe: potential land use and climate impacts in 2050,” <i>Reg Environ Change</i>, vol. 17, no. 2, pp. 367–377, Feb. 2017, doi: <DOILink doi="10.1007/s10113-016-1013-4" />.</Fragment> },
    // “technical and production-side mitigation strategies alone would not suffice to reach this [2 degree] target and that dietary change would also be needed” … “The artificial meat and plant-based scenarios achieved the greatest land use and GHG reductions and the greatest carbon sequestration potential.” 
    bajzeljEtAl_ghg2050_2014: { url: "https://doi.org/10.1038/nclimate2353", full: <Fragment>B. Bajželj et al., “Importance of food-demand management for climate mitigation,” <i>Nature Clim Change</i>, vol. 4, no. 10, pp. 924–929, Oct. 2014, doi: <DOILink doi="10.1038/nclimate2353" />.</Fragment> },
    // “The agriculture-related emissions in our business-as-usual scenario (CT1) alone almost reach the full 2C target emissions allowance in 2050” 
    willetEtAl_parisAgreeement_2019: { url: "https://doi.org/10.1016/s0140-6736(18)31788-4", full: <Fragment>W. Willett et al., “Food in the Anthropocene: the EAT–Lancet Commission on healthy diets from sustainable food systems,” <i>The Lancet</i>, vol. 393, no. 10170, pp. 447–492, Feb. 2019, doi: <DOILink doi="10.1016/S0140-6736(18)31788-4" />.</Fragment> },
    // "“Transformation to healthy diets from sustainable food systems is necessary to achieve the UN Sustainable Development Goals and the Paris Agreement” "
    // also Hayek et al

    coimbraEtAl_leadingBiodiversityLoss: { url: "https://doi.org/10.1016/j.pecon.2020.10.002", full: <Fragment>Z. H. Coimbra, L. Gomes-Jr, and F. A. S. Fernandez, “Human carnivory as a major driver of vertebrate extinction,” <i>Perspectives in Ecology and Conservation</i>, vol. 18, no. 4, pp. 283–293, Oct. 2020, doi: <DOILink doi="10.1016/j.pecon.2020.10.002" />.</Fragment> },
    // Our results suggest that human carnivory is the major driver of the current biodiversity crisis
    machovinaEtAl_leadingSpeciesExtinction: { url: "https://doi.org/10.1016/j.scitotenv.2015.07.022", full: <Fragment>B. Machovina, K. J. Feeley, and W. J. Ripple, “Biodiversity conservation: The key is reducing meat consumption,” <i>Science of The Total Environment</i>, vol. 536, pp. 419–431, Dec. 2015, doi: <DOILink doi="10.1016/j.scitotenv.2015.07.022" />.</Fragment> },
    // Though difficult to quantify, animal product consumption by humans (human carnivory) is likely the leading cause of modern species extinctions, since it is not only the major driver of deforestation but also a principle driver of land degradation, pollution, climate change, overfishing, sedimentation of coastal areas, facilitation of invasions by alien species, (Steinfeld et al., 2006a) and loss of wild carnivores (Ripple et al., 2014a) and wild herbivores (Ripple et al., 2015).
    hendersEtAl_leadingDeforestation: { url: "https://doi.org/10.1088/1748-9326/10/12/125012", full: <Fragment>S. Henders, U. M. Persson, and T. Kastner, “Trading forests: land-use change and carbon emissions embodied in production and exports of forest-risk commodities,” <i>Environ. Res. Lett.</i>, vol. 10, no. 12, p. 125012, Dec. 2015, doi: <DOILink doi="10.1088/1748-9326/10/12/125012" />.</Fragment> },
    // In 2011, beef was the main driver of forest loss across our case countries, accounting for nearly 60 percent of embodied deforestation" Trading forests: land-use change and carbonemissions embodied in production and exports offorest-risk commodities

    // deforestation: { url: "https://ourworldindata.org/drivers-of-deforestation", full: "" },
    // savoryDebunk: { url: "https://publications.lib.chalmers.se/records/fulltext/244566/local_244566.pdf", full: "" },
    hayekEtAl_sequester163Percent: { url: "https://doi.org/10.1038/s41893-020-00603-4", full: <Fragment>M. N. Hayek, H. Harwatt, W. J. Ripple, and N. D. Mueller, “The carbon opportunity cost of animal-sourced food production on land,” <i>Nat Sustain</i>, vol. 4, no. 1, pp. 21–24, Jan. 2021, doi: <DOILink doi="10.1038/s41893-020-00603-4" />.</Fragment> },
    hahnEtAl_childrenAgentsOfProtection: { url: "https://doi.org/10.1016/j.jenvp.2021.101705", full: <Fragment>E. R. Hahn, M. Gillogly, and B. E. Bradford, “Children are unsuspecting meat eaters: An opportunity to address climate change,” <i>Journal of Environmental Psychology</i>, vol. 78, p. 101705, Dec. 2021, doi: <DOILink doi="10.1016/j.jenvp.2021.101705" />.</Fragment> },
    sheponEtAl_feedConversions: { url: "https://doi.org/10.1088/1748-9326/11/10/105002", full: <Fragment>A. Shepon, G. Eshel, E. Noor, and R. Milo, “Energy and protein feed-to-food conversion efficiencies in the US and potential food security gains from dietary changes,” <i>Environ. Res. Lett.</i>, vol. 11, no. 10, p. 105002, Oct. 2016, doi: <DOILink doi="10.1088/1748-9326/11/10/105002" />.</Fragment> },

    // grass fed worse for environment than factory farmed
    hayekEtAl_ghg15deg_grassFedBeefScale27: { url: "https://doi.org/10.1088/1748-9326/aad401", full: <Fragment>M. N. Hayek and R. D. Garrett, “Nationwide shift to grass-fed beef requires larger cattle population,” <i>Environ. Res. Lett.</i>, vol. 13, no. 8, p. 084005, Jul. 2018, doi: <DOILink doi="10.1088/1748-9326/aad401" />.</Fragment> },
    grassFed30Higher: { url: "https://doi.org/10.1016/j.agsy.2010.03.009", full: <Fragment>N. Pelletier, R. Pirog, and R. Rasmussen, “Comparative life cycle environmental impacts of three beef production strategies in the Upper Midwestern United States,” <i>Agricultural Systems</i>, vol. 103, no. 6, pp. 380–389, Jul. 2010, doi: <DOILink doi="10.1016/j.agsy.2010.03.009" />.</Fragment> },
    grassFed67Higher: { url: "https://doi.org/10.3390/ani2020127", full: <Fragment>J. L. Capper, “Is the Grass Always Greener? Comparing the Environmental Impact of Conventional, Natural and Grass-Fed Beef Production Systems,” Animals, vol. 2, no. 2, pp. 127–143, Apr. 2012, doi: <DOILink doi="10.3390/ani2020127" />.</Fragment> },
    grassFed37Higher: { url: "https://doi.org/10.2134/jeq2013.03.0101", full: <Fragment>C. D. Lupo, D. E. Clay, J. L. Benning, and J. J. Stone, “Life-Cycle Assessment of the Beef Cattle Production System for the Northern Great Plains, USA,” <i>J. Environ. Qual.</i>, vol. 42, no. 5, pp. 1386–1394, Sep. 2013, doi: <DOILink doi="10.2134/jeq2013.03.0101" />.</Fragment> },

    // justice section
    aphKo_racismAsZoologicalWitchcraft: { url: "https://isbnsearch.org/isbn/1590565967", full: <Fragment>A. Ko, <i>Racism as zoological witchcraft: a guide for getting out</i>. Brooklyn, NY: Lantern Books, 2019.</Fragment>},
    aphroism: { url: "https://isbnsearch.org/isbn/159056555X", full: <Fragment>Ko, Aph, and Syl Ko. <i>Aphro-Ism: Essays on Pop Culture, Feminism, and Black Veganism from Two Sisters</i>. New York: Lantern Books, 2017.</Fragment>},
    bastianEtAl_humanAnimalFramingEssayExperiment: { url: "https://doi.org/10.1177/1948550611425106", full: <Fragment>B. Bastian, K. Costello, S. Loughnan, and G. Hodson, “When Closing the Human–Animal Divide Expands Moral Concern: The Importance of Framing,” <i>Social Psychological and Personality Science</i>, vol. 3, no. 4, pp. 421–429, Jul. 2012, doi: <DOILink doi="10.1177/1948550611425106" />.</Fragment>},
    costelloEtAl_humanAnimalFraming_ImmigrantHumanization: { url: "https://doi.org/10.1177/1368430209347725", full: <Fragment>K. Costello and G. Hodson, “Exploring the roots of dehumanization: The role of animal—human similarity in promoting immigrant humanization,” <i>Group Processes &amp; Intergroup Relations</i>, vol. 13, no. 1, pp. 3–22, Jan. 2010, doi: <DOILink doi="10.1177/1368430209347725" />.</Fragment>},
    // "premise that dehumanization originates from an exaggerated human–animal divide"
    // "experimental inducements of animal–human similarity could decrease the utility of dehumanization as a contributor to immigrant prejudice"
    // "The humanization process was theoretically expected to draw the outgroup closer to the ingroup by making outgroup members psychologically more human in nature"
    // "By isolating a powerful origin of dehumanizing perceptions (i.e., the animal–human divide), we targeted and influenced the roots of dehumanization, removing the legitimacy of such perceptions altogether"
    // "lower anti-immigrant prejudice by robbing participants of the ability to dehumanize the outgroup, given that dehumanization depends on a perceived divide between humans and nonhuman animals (see Haslam, 2006; Patterson, 2002)"
    vegetarianResourceGroup_2xBlackVegans: { url: "https://www.vrg.org/press/201511press.htm", full: <Fragment>C. Stahler, “How many adult vegetarians in the U.S.?,” Vegetarian Resource Group, 2015. Accessed: Jan. 03, 2022. [Online]. Available: <DottedExternalLink url="https://www.vrg.org/press/201511press.htm" /></Fragment>},
    // supports the "premise that dehumanization originates from an exaggerated human–animal divide"
    // "ower anti-immigrant prejudice by robbing participants of the ability to dehumanize the outgroup, given that dehumanization depends on a perceived divide between humans and nonhuman animals (see Haslam, 2006; Patterson, 2002)"
    // https://faunalytics.org/wp-content/uploads/2015/05/Citation169.pdf
    // pewResearchCenter_blackPeople2xMoreLikelyVegan: { url: "https://www.pewresearch.org/internet/wp-content/uploads/sites/9/2016/11/PS_2016.12.01_Food-Science_FINAL.pdf", full: <Fragment>C. Funk and B. Kennedy, “U.S. Public Divides Over Food Science,” Pew Research Center, Dec. 2016.</Fragment> },
    // also: "Those focused on healthy and nutritious eating are also more likely to report they have allergic reactions to food: 26% have at least a mild food allergy, compared with 15% among those with little or no focus on healthy eating. "
    // also pdf p. 27: vegans lean left
    // pdf p. 27: "Among adults with food allergies, 21% identify as strictly or mostly following vegan or vegetarian diets. Just 8% of adults with food intolerances (but no allergies) and 6% of adults with neither food allergies nor intolerances are vegan or vegetarian. "
    
    // # vegans over time: https://news.gallup.com/poll/238328/snapshot-few-americans-vegetarian-vegan.aspx
    morlandEtAl_supermarketsIn8PercentOfBlackCommunities: { url: "https://doi.org/10.2105/AJPH.92.11.1761", full: <Fragment>K. Morland, S. Wing, and A. D. Roux, “The Contextual Effect of the Local Food Environment on Residents’ Diets: The Atherosclerosis Risk in Communities Study,” <i>Am J Public Health</i>, vol. 92, no. 11, pp. 1761–1768, Nov. 2002, doi: <DOILink doi="10.2105/AJPH.92.11.1761" />.</Fragment> },
    piltchEtAl_navajo13groceries: { url: "https://dx.doi.org/10.1017%2FS1368980019003720", full: <Fragment>E. M. Piltch, S. S. Shin, R. F. Houser, and T. Griffin, “The complexities of selling fruits and vegetables in remote Navajo Nation retail outlets: perspectives from owners and managers of small stores,” <i>Public Health Nutr.</i>, vol. 23, no. 9, pp. 1638–1646, Jun. 2020, doi: <DOILink doi="10.1017/S1368980019003720" />.</Fragment> },
    // navajo driving time (not yet peer reviewed): https://ph.byu.edu/byu-researchers-map-driving-times-to-food-sources-in-the-navajo-nation
    meatsplaining: { url: "https://doi.org/10.2307/j.ctv15tt7dr", full: <Fragment>J. Hannan, Ed., <i>Meatsplaining: The Animal Agriculture Industry and the Rhetoric of Denial</i>. Sydney University Press, 2020. doi: <DOILink doi="10.2307/j.ctv15tt7dr" />.</Fragment> },
    worrall_meatpackingSafety: { url: "https://aglawjournal.wp.drake.edu/wp-content/uploads/sites/66/2016/09/agVol09No2-Worrall.pdf", full: <Fragment>M. S. Worrall, “Meatpacking Safety: Is OSHA Enforcement Adequate?,” <i>Drake Journal of Agricultural Law</i>, vol. 9, no. 2, pp. 310–312, 2005.</Fragment> },
    hrw_mostAbattoirsPOC: { url: "https://www.hrw.org/sites/default/files/report_pdf/us0919_web.pdf", full: <Fragment>B. Stauffer, “‘When We’re Dead and Buried, Our Bones Will Keep Hurting’: Workers’ Rights Under Threat in US Meat and Poultry Plants,” Human Rights Watch, Sep. 2019.</Fragment> },
    // bureauLaborStats_slaughterhouseDemographics: {url: "https://www.bls.gov/cps/cpsaat18.htm", full: ""},
    dillard_PITS: { url: "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=1016401", full: <Fragment>J. Dillard, “A Slaughterhouse Nightmare: Psychological Harm Suffered by Slaughterhouse Employees and the Possibility of Redress through Legal Reform,” <i>Georgetown Journal on Poverty Law &amp; Policy</i>, Sep. 2007.</Fragment> },
    usda100Turnover: { url: "https://www.ers.usda.gov/webdocs/publications/41108/18011_aer785_1_.pdf?v=0", full: <Fragment>“Consolidation in U.S. Meatpacking,” United States Department of Agriculture, Agriculture Economic Report 785, 2000. doi: <DOILink doi="10.22004/ag.econ.34021" />.</Fragment> },
    mirabelliEtAl_pigCAFOsNearPOC: { url: "https://doi.org/10.1289/ehp.8586", full: <Fragment>M. C. Mirabelli, S. Wing, S. W. Marshall, and T. C. Wilcosky, “Race, Poverty, and Potential Exposure of Middle-School Students to Air Emissions from Confined Swine Feeding Operations,” <i>Environmental Health Perspectives</i>, vol. 114, no. 4, pp. 591–596, Apr. 2006, doi: <DOILink doi="10.1289/ehp.8586" />.</Fragment> },
    wilsonEtAl_CAFOsNearPOCMississippi: { url: "https://doi.org/10.1289/ehp.02110s2195", full: <Fragment>S. M. Wilson, F. Howell, S. Wing, and M. Sobsey, “Environmental injustice and the Mississippi hog industry.,” <i>Environmental Health Perspectives</i>, vol. 110, no. suppl 2, pp. 195–201, Apr. 2002, doi: <DOILink doi="10.1289/ehp.02110s2195" />.</Fragment> },
    kravchenkoEtAl_CAFOzipCodes: { url: "https://doi.org/10.18043/ncm.79.5.278", full: <Fragment>J. Kravchenko, S. H. Rhew, I. Akushevich, P. Agarwal, and H. K. Lyerly, “Mortality and Health Outcomes in North Carolina Communities Located in Close Proximity to Hog Concentrated Animal Feeding Operations,” <i>North Carolina Medical Journal</i>, vol. 79, no. 5, pp. 278–288, Sep. 2018, doi: <DOILink doi="10.18043/ncm.79.5.278" />.</Fragment> },
    bullers_hogFarmRespiratoryIllness: { url: "https://doi.org/10.1007/s10745-005-1653-3", full: <Fragment>S. Bullers, “Environmental Stressors, Perceived Control, and Health: The Case of Residents Near Large-Scale Hog Farms in Eastern North Carolina,” <i>Hum Ecol</i>, vol. 33, no. 1, pp. 1–16, Feb. 2005, doi: <DOILink doi="10.1007/s10745-005-1653-3" />.</Fragment> },
    coleEtAl_hogFarmHealthEffects: { url: "https://doi.org/10.1289/ehp.00108685", full: <Fragment>D. Cole, L. Todd, and S. Wing, “Concentrated swine feeding operations and public health: a review of occupational and community health effects.,” <i>Environmental Health Perspectives</i>, vol. 108, no. 8, pp. 685–699, Aug. 2000, doi: <DOILink doi="10.1289/ehp.00108685" />.</Fragment> },
    wingEtAl_nCarolinaHealthEffects: { url: "https://doi.org/10.1289/ehp.00108233", full: <Fragment>S. Wing and S. Wolf, “Intensive livestock operations, health, and quality of life among eastern North Carolina residents.,” <i>Environmental Health Perspectives</i>, vol. 108, no. 3, pp. 233–238, Mar. 2000, doi: <DOILink doi="10.1289/ehp.00108233" />.</Fragment> },
    wingEtAl2_cafoBloodPressure: { url: "https://doi.org/10.1289/ehp.1205109", full: <Fragment>S. Wing, R. A. Horton, and K. M. Rose, “Air Pollution from Industrial Swine Operations and Blood Pressure of Neighboring Residents,” <i>Environmental Health Perspectives</i>, vol. 121, no. 1, pp. 92–96, Jan. 2013, doi: <DOILink doi="10.1289/ehp.1205109" />.</Fragment> },
    wingEtAl3_cafosNearPOC: { url: "https://doi.org/10.1289/ehp.00108225", full: <Fragment>S. Wing, D. Cole, and G. Grant, “Environmental injustice in North Carolina’s hog industry.,” <i>Environmental Health Perspectives</i>, vol. 108, no. 3, pp. 225–231, Mar. 2000, doi: <DOILink doi="10.1289/ehp.00108225" />.</Fragment> },
    schiffmanEtAl_cafoMentalHealth: { url: "https://doi.org/10.1289/ehp.121-a182", full: <Fragment>W. Nicole, “CAFOs and Environmental Justice: The Case of North Carolina,” <i>Environmental Health Perspectives</i>, vol. 121, no. 6, Jun. 2013, doi: <DOILink doi="10.1289/ehp.121-a182" />.</Fragment> },
    hortonEtAl_cafoNegativeMoodStress: { url: "https://doi.org/10.2105/AJPH.2008.148924", full: <Fragment>R. A. Horton, S. Wing, S. W. Marshall, and K. A. Brownley, “Malodor as a Trigger of Stress and Negative Mood in Neighbors of Industrial Hog Operations,” <i>Am J Public Health</i>, vol. 99, no. S3, pp. S610–S615, Nov. 2009, doi: <DOILink doi="10.2105/AJPH.2008.148924" />.</Fragment> },
    // schiffmanEtAl_CAFOhealth: {url: "https://doi.org/10.1016/0361-9230(95)00015-1" full: ""},
    // North Carolina https://dx.doi.org/10.1289%2Fehp.121-a182
    edwardsEtAl_POCfarmLoss: { url: "https://doi.org/10.1080/027321700405054", full: <Fragment>Bob Edwards, Anthony E. Ladd, “Environmental Justice, Swine Production and Farm Loss in North Carolina,” <i>Sociological Spectrum</i>, vol. 20, no. 3, pp. 263–290, Jul. 2000, doi: <DOILink doi="10.1080/027321700405054" />.</Fragment> },
    durrenbergerEtAl_CAFOinequality: { url: "https://doi.org/10.17730/humo.55.4.wtn74681u08t882v", full: <Fragment>E. Durrenberger and K. Thu, “The Expansion of Large Scale Hog Farming in Iowa: The Applicability of Goldschmidt’s Findings Fifty Years Later,” <i>Human Organization</i>, vol. 55, no. 4, pp. 409–415, Dec. 1996, doi: <DOILink doi="10.17730/humo.55.4.wtn74681u08t882v" />.</Fragment> },
    burkholderEtAl_cafoWaterContamination: { url: "https://doi.org/10.1289/ehp.8839", full: <Fragment>J. Burkholder et al., “Impacts of Waste from Concentrated Animal Feeding Operations on Water Quality,” <i>Environmental Health Perspectives</i>, vol. 115, no. 2, pp. 308–312, Feb. 2007, doi: <DOILink doi="10.1289/ehp.8839" />.</Fragment> },
    burkholderEtAl2_cafoSpillIntoWater: { url: "https://doi.org/10.2134/jeq1997.00472425002600060003x", full: <Fragment>J. M. Burkholder et al., “Impacts to a Coastal River and Estuary from Rupture of a Large Swine Waste Holding Lagoon,” <i>J. environ. qual.</i>, vol. 26, no. 6, pp. 1451–1466, Nov. 1997, doi: <DOILink doi="10.2134/jeq1997.00472425002600060003x" />.</Fragment> },
    huffmanEtAl_cafoGroundwaterContamination: { url: "https://doi.org/10.13031/2013.17630", full: <Fragment>R. L. Huffman, “Seepage Evaluation Of Older Swine Lagoons In North Carolina,” <i>Transactions of the ASAE</i>, vol. 47, no. 5, pp. 1507–1512, 2004, doi: <DOILink doi="10.13031/2013.17630" />.</Fragment> },
    essenEtAl_cafoAirContamination: { url: "https://doi.org/10.1300/j096v10n04_08", full: <Fragment>S. G. Von Essen and B. W. Auvermann, “Health Effects from Breathing Air Near CAFOs for Feeder Cattle or Hogs,” <i>Journal of Agromedicine</i>, vol. 10, no. 4, pp. 55–64, Dec. 2005, doi: <DOILink doi="10.1300/J096v10n04_08" />.</Fragment> },
    // Dr. Greger's paper https://doi.org/10.1097/fch.0b013e3181c4e22a
    // slaughterhouse workers
    // https://sentientmedia.org/profit-over-people-the-meat-industrys-exploitation-of-vulnerable-workers/
    // https://www.hrw.org/sites/default/files/reports/usa0105.pdf

    
    // right-wing ideology has downstream effects on views towards animals https://kar.kent.ac.uk/80384/
    // Right wingers engage in more animal exploitation and meat consumption https://www.sciencedirect.com/science/article/abs/pii/S0191886914000944

    // psychology of eating animals
    loughnanEtAl_randomAssignFoodAffectsConcern: { url: "https://doi.org/10.1016/j.appet.2010.05.043", full: <Fragment>S. Loughnan, N. Haslam, and B. Bastian, “The role of meat consumption in the denial of moral status and mind to meat animals,” <i>Appetite</i>, vol. 55, no. 1, pp. 156–159, Aug. 2010, doi: <DOILink doi="10.1016/j.appet.2010.05.043" />.</Fragment> },
    wilksEtAl_childrenVsAdultsSacrificeSpecies: { url: "https://doi.org/10.1177/0956797620960398", full: <Fragment>M. Wilks, L. Caviola, G. Kahane, and P. Bloom, “Children Prioritize Humans Over Animals Less Than Adults Do,” <i>Psychol Sci</i>, vol. 32, no. 1, pp. 27–38, Jan. 2021, doi: <DOILink doi="10.1177/0956797620960398" />.</Fragment> },
    bratanovaEtAl_edibleReducesPercievedSuffering: { url: "https://doi.org/10.1016/j.appet.2011.04.020", full: <Fragment>B. Bratanova, S. Loughnan, and B. Bastian, “The effect of categorization as food on the perceived moral standing of animals,” <i>Appetite</i>, vol. 57, no. 1, pp. 193–196, Aug. 2011, doi: <DOILink doi="10.1016/j.appet.2011.04.020" />.</Fragment>},
    // opotowEtAl_utilityDecreasesMoralConcern: { url: "https://doi.org/10.1111/j.1540-4560.1993.tb00909.x", full: <Fragment>S. Opotow, “Animals and the Scope of Justice,” <i>Journal of Social Issues</i>, vol. 49, no. 1, pp. 71–85, Apr. 1993, doi: <DOILink doi="10.1111/j.1540-4560.1993.tb00909.x" />.</Fragment> },
    
    bilewiczEtAl_edibleFaceRecognitionBrainActivity: { url: "https://doi.org/10.1016/j.appet.2016.06.014", full: <Fragment>M. Bilewicz, J. Michalak, and O. K. Kamińska, “Facing the edible. The effects of edibility information on the neural encoding of animal faces,” <i>Appetite</i>, vol. 105, pp. 542–548, Oct. 2016, doi: <DOILink doi="10.1016/j.appet.2016.06.014" />.</Fragment>},
    // Dehumanization: "This process allows for the maintenance of a moral self-image while exploiting and killing outgroup members."
    // "outgroup members e even in arbitrarily created groups e are perceived as less human than ingroup members (Leyens, Demoulin, Vaes, Gaunt, & Paladino, 2007)."
    bem_selfPerceptionTheory: { url: "https://psycnet.apa.org/doi/10.1037/h0024835", full: <Fragment>D. J. Bem, “Self-perception: An alternative interpretation of cognitive dissonance phenomena.,” <i>Psychological Review</i>, vol. 74, no. 3, pp. 183–200, 1967, doi: <DOILink doi="10.1037/h0024835" />.</Fragment>},
    // "Human supremacy showed longitudinal effects on the moral exclusion of all animals" https://doi.org/10.1002/ejsp.2497
    // semi vegetarians less likely to "dislike meat and find it disgusting" https://doi.org/10.1016/j.appet.2013.10.002
    // speciesist language study https://doi.org/10.31234/osf.io/ktvgx
    // gracaEtAl_meatComsumptionIncreasesMeatAttachment
    // visually associating animals w/ meat decreased antiveganism https://doi.org/10.1177%2F1368430219861848
    // personal responsibility increase climate mitigation intentions https://www.sciencedirect.com/science/article/abs/pii/S027249442100164X
    // "issue salience" promoted policy support https://www.researchgate.net/publication/271671424_Information_sufficiency_and_attribution_of_responsibility_Predicting_support_for_climate_change_policy_and_pro-environmental_behavior
    // guilt mediates behavior change https://www.sciencedirect.com/science/article/pii/S0272494421001420
    // "Personal efficacy beliefs strongly predict intended behaviour change" https://doi.org/10.1016/j.jenvp.2021.101655  
    // gain-positive framing increases hope but decreases action https://journals.sagepub.com/doi/abs/10.1177/1075547017718553
    // "sequencing emotional experiences to enhance persuasive effect" https://doi.org/10.1177%2F1075547018776019
    // sadness accompanied with gain, or hope juxtaposed with loss https://journals.sagepub.com/doi/10.1177/1075547015619173?icid=int.sj-abstract.similar-articles.2
    // fear, guilt, shame less effective https://journals.sagepub.com/doi/10.1177/1075547019889637?icid=int.sj-abstract.similar-articles.3
    // "the positive relationship between behavioural willingness and pro-environmental behaviour was moderated by constructive hope" https://doi.org/10.1080/13504622.2021.2015295
    // behavioral intentions do not necessarily translate into action https://www.sps.ed.ac.uk/sites/default/files/assets/pdf/Howell_AoS_paper_2_pre-print.pdf
    // right wing cognitive ability https://doi.org/10.1002%2Fper.2027

    // consent 
    news_dairyCowsCryOut: { url: "https://www.newburyportnews.com/news/local_news/strange-noises-turn-out-to-be-cows-missing-their-calves/article_d872e4da-b318-5e90-870e-51266f8eea7f.html", full: <Fragment>D. R. Writer, “Strange noises turn out to be cows missing their calves,” The Daily News of Newburyport, Oct. 23, 2013. <DottedExternalLink url="https://www.newburyportnews.com/news/local_news/strange-noises-turn-out-to-be-cows-missing-their-calves/article_d872e4da-b318-5e90-870e-51266f8eea7f.html" /> (accessed Feb. 02, 2022).</Fragment> },
    usda_impregnateCows: { url: "https://www.aphis.usda.gov/animal_health/nahms/dairy/downloads/bamn/BAMN07_HeiferGrowth.pdf", full: <Fragment>[1]K. Peart, “Heifer Growth and Economics: Target Growth.” Bovine Alliance on Management & Nutrition, 2007. Accessed: Feb. 02, 2022. [Online]. Available: <DottedExternalLink url="https://www.aphis.usda.gov/animal_health/nahms/dairy/downloads/bamn/BAMN07_HeiferGrowth.pdf" /></Fragment>},
    // unhappy after separated from mothers https://doi.org/10.1371/journal.pone.0098429


    // health
    // academyOfNutritionAndDietics: { url: "https://doi.org/10.1016/j.jand.2016.09.025", full: <Fragment>V. Melina, W. Craig, and S. Levin, “Position of the Academy of Nutrition and Dietetics: Vegetarian Diets,” <i>Journal of the Academy of Nutrition and Dietetics</i>, vol. 116, no. 12, pp. 1970–1980, Dec. 2016, doi: <DOILink doi="10.1016/j.jand.2016.09.025" />.</Fragment>},
    // protein https://doi.org/10.3390/nu11112661 / https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6893534/
    // cost
    // https://doi.org/10.1080/19320248.2015.1045675

    // hunting
    deckha_huntingQuote: { url: "https://doi.org/10.1080/07256868.2019.1704229", full: <Fragment>M. Deckha, “Unsettling Anthropocentric Legal Systems: Reconciliation, Indigenous Laws, and Animal Personhood,” <i>Journal of Intercultural Studies</i>, vol. 41, no. 1, pp. 77–97, Jan. 2020, doi: <DOILink doi="10.1080/07256868.2019.1704229" />.</Fragment>},

    // Food is a Social Construct flyer
    imfeld2011_pigsAnticipation: { url: "https://doi.org/10.1016/j.applanim.2011.02.009", full: <Fragment>S. Imfeld-Mueller, L. Van Wezemael, M. Stauffacher, L. Gygax, and E. Hillmann, “Do pigs distinguish between situations of different emotional valences during anticipation?,” Applied Animal Behaviour Science, vol. 131, no. 3, pp. 86–93, May 2011, doi: <DOILink doi="10.1016/j.applanim.2011.02.009" />.</Fragment>},
    joy_carnismTED: { url: "https://www.youtube.com/watch?v=o0VrZPBskpg", full: <Fragment>M. Joy, “Toward Rational, Authentic Food Choices,” Feb. 15, 2015.</Fragment>},
    marino2017_chickensOverview: { url: "https://doi.org/10.1007/s10071-016-1064-4", full: <Fragment>L. Marino, “Thinking chickens: a review of cognition, emotion, and behavior in the domestic chicken,” Anim Cogn, vol. 20, no. 2, pp. 127–147, Mar. 2017, doi: <DOILink doi="10.1007/s10071-016-1064-4" />.</Fragment>},
    reimert2014_pigsSocialContagion: { url: "https://doi.org/10.1007/s10071-014-0820-6", full: <Fragment>I. Reimert, J. E. Bolhuis, B. Kemp, and T. B. Rodenburg, “Emotions on the loose: emotional contagion and the role of oxytocin in pigs,” Anim Cogn, vol. 18, no. 2, pp. 517–532, Mar. 2015, doi: <DOILink doi="10.1007/s10071-014-0820-6" />.</Fragment>},
    souza2006_pigsSocialRecognition: { url: "https://doi.org/10.1016/j.applanim.2005.09.008", full: <Fragment>A. S. Souza, J. Jansen, R. J. Tempelman, M. Mendl, and A. J. Zanella, “A novel method for testing social recognition in young pigs and the modulating effects of relocation,” Applied Animal Behaviour Science, vol. 99, no. 1, pp. 77–87, Aug. 2006, doi: <DOILink doi="10.1016/j.applanim.2005.09.008" />.</Fragment>},

    // Environment flyer (extra sources)
    cassidy_feedExtra4Billion: { url: "https://doi.org/10.1088/1748-9326/8/3/034015", full: <Fragment>E. S. Cassidy, P. C. West, J. S. Gerber, and J. A. Foley, “Redefining agricultural yields: from tonnes to people nourished per hectare,” Environ. Res. Lett., vol. 8, no. 3, p. 034015, Aug. 2013, doi: <DOILink doi="10.1088/1748-9326/8/3/034015" />.</Fragment>},
    springmann2021_cheapDiets: { url: "https://doi.org/10.1016/S2542-5196(21)00251-5", full: <Fragment>M. Springmann, M. A. Clark, M. Rayner, P. Scarborough, and P. Webb, “The global and regional costs of healthy and sustainable dietary patterns: a modelling study,” Lancet Planet Health, vol. 5, no. 11, pp. e797–e807, Oct. 2021, doi: <DOILink doi="10.1016/S2542-5196(21)00251-5" />.</Fragment>},
} as const

// science for "can they suffer?" being what determines empathy for animals https://doi.org/10.1002/ejsp.2718
// Childhood companion animal -> flesh consumption ↓, indirect justification ↑ https://doi.org/10.1016/j.appet.2014.03.032
// Types of meat eaters (indifferent, struggling, coping, strategic ignorant) https://www.sciencedirect.com/science/article/abs/pii/S0950329316300738
// Conscious ominvores violate their diet more and feel less guilty than veg https://www.sciencedirect.com/science/article/abs/pii/S0195666314004929
// Tailoring outreach based on stage https://www.sciencedirect.com/science/article/abs/pii/S0272494417300063
// normal, natural, necessary, nice -> less motivated by ethical concerns https://www.sciencedirect.com/science/article/abs/pii/S0195666315001518
// animal moral standing inc. as beauty inc. https://www.sciencedirect.com/science/article/abs/pii/S0272494421000773
// "These findings suggest that people, especially those highly committed to eating meat, willfully disregard solutions targeting animal agriculture and global meat consumption to prevent future pandemics precisely because such solutions implicate their dietary habits" https://www.sciencedirect.com/science/article/abs/pii/S0195666321001860
// current vegetarians more likely to have made gradual transition  https://doi.org/10.1016/j.appet.2012.02.048

// ****
// "The results demonstrate that categorization as food – but not killing or human responsibility – was sufficient to reduce the animal's perceived capacity to suffer, which in turn restricted moral concern"  https://www.sciencedirect.com/science/article/abs/pii/S0195666311001413


// Dr. Claire Jean Kim 2016 https://unboundproject.org/animals-feminism-and-antiblackness-2/
// "the human-animal hierarchy is mapped onto, and secured by, an antiblack racial order.  For animal studies, and feminist studies, too, the path forward goes through, not around, black studies."
// also https://journals.lub.lu.se/pa/article/view/16274

// also https://www.academia.edu/40305093/In_On_the_Bones_species_meanings_and_the_racialising_discourse_of_animality_in_the_Homo_naledi_controversy
// "This would entail undoing “theanimal”, which, Felice Cimatti observes, ‘is an invention, the sum of what we do notrecognize as human’ (Cimatti, in Salzani 2016:108)"


// Animal: New Directions in the Theorization of Race and Posthumanism
// https://www.jstor.org/stable/23719431
// Syl Ko: Notes From the Border of the Human-Animal Divide
// https://web.archive.org/web/20160513215852/https://aphro-ism.com/2016/01/13/notes-from-the-border-of-the-human-animal-divide-thinking-and-talking-about-animal-oppression-when-youre-not-quite-human-yourself/
// scientific support: "the results of the current study (N = 191) demonstrate that endorsing speciesist attitudes is significantly and positively associated with negative attitudes toward ethnic outgroups" https://doi.org/10.1016/j.paid.2013.12.020
// 2. ", devaluing animals increases the social value of representing other social groups as animal-like, thus denying these human groups the protections otherwise afforded to humans" https://www.taylorfrancis.com/chapters/edit/10.4324/9781351181440-5/devaluing-animals-animalistic-humans-people-protect-animals-gordon-hodson-kristof-dhont-megan-earle
// 3. right wing assoc with animal consumption https://doi.org/10.1016/j.paid.2014.02.002

// The Possessive Investment in Whiteness: How White People Profit from Identity Politics
// https://smile.amazon.com/Possessive-Investment-Whiteness-Identity-Politics/dp/1592134947?sa-no-redirect=1

// animals have personalities https://doi.org/10.1016/j.applanim.2021.105400

// vivisection
// chimps meta analysis https://doi.org/10.1080/10888700701555501
// even despite dna similarity https://doi.org/10.1177/026119291103900608
// chimps ban https://pubmed.ncbi.nlm.nih.gov/18518999/
// chimps -- ineffective for cancer research https://pubmed.ncbi.nlm.nih.gov/19807212/
// story of a chimp https://www.nature.com/articles/474268a#citeas

// pandemics
// dr greger overview https://doi.org/10.1177%2F15598276211008134
// poor & bipoc people more vulnerable to diseases, mentioned in https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7399585/
// (2015) 80% antibiotics used by animal ag https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4638249/
// lactose intolerance
// https://www.ahrq.gov/downloads/pub/evidence/pdf/lactoseint/lactint.pdf,  pdf page 59 (garza 1976)
// also p 63 -- nicklas et al
// https://www.nrdc.org/experts/david-wallinga-md/most-human-antibiotics-still-going-us-meat-production
interface Props {
    url: string;
    // pageString: string | undefined;
}

// global
const citationURLsToNumbers: Map<string, number> = new Map()
export const InlineCitation: FunctionComponent<Props> = ({ url, /*pageString*/ }: Props) => {
    let number = citationURLsToNumbers.get(url)
    if (!number) {
        number = citationURLsToNumbers.size + 1 // 1-indexed
        citationURLsToNumbers.set(url, number)
    }
    return <DimSimpleLink href={url} target="_blank" rel="noopener noreferrer">[{number}{/*pageString*/}]</DimSimpleLink>
}

export const Bibliography: FunctionComponent = () => {
    return <div style={{ display: "flex", flexDirection: "column" }}>
        {Object.values(Citations).map(citation => {
            const citationNum = citationURLsToNumbers.get(citation.url)
            if (!citationNum) return null
            return <FullCitation key={citationNum} style={{ order: citationNum }}>
                [{citationNum}] {citation.full}
            </FullCitation>
        })}
    </div>
}

export const FullCitation = styled("div")`
    text-indent: -8ch;
    margin-left: 8ch;
    margin-bottom: 0.2em;
    line-break: loose;
`