import { MdChevronRight } from 'react-icons/md'
import { Fragment, FunctionalComponent, h } from 'preact';
import { useState, useRef } from 'preact/hooks';
import { Paragraph } from './typography';
import { css, styled } from 'goober';


const ItemIcon = styled(MdChevronRight) <{ active: boolean }>`
  vertical-align: middle;
  transition: transform 1s;
  transform: rotateZ(${(props): string => props.active ? "90deg" : "0deg"});
`;

const ItemHandle = styled("span")`
  cursor: pointer;
  font-weight: bold;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 1.65em;
  transition: all 0.7s;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
`;

type ItemProps = {
    active: boolean;
    title: string | JSX.Element;
    body: string | JSX.Element;
    onClick?: () => void;
};


// Used instead of styled component to avoid preact-compat (see Item comments below)
const itemBodyCSS = css`
  transition: all 1s;
  overflow-y: hidden;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
`;
const Item: FunctionalComponent<ItemProps> = ({ active, title, body, onClick }: ItemProps) => {
    const bodyRef = useRef<HTMLDivElement>(null);
    return <div>
        <ItemHandle onClick={onClick}><ItemIcon active={active} />{title}</ItemHandle>
        {/* Hack used because goober doesn't support refs unless I add in preact-compat */}
        <div ref={bodyRef} class={itemBodyCSS} style={{maxHeight: active ? `${bodyRef.current?.scrollHeight}px` : "0px"}} >
            {body}
        </div>
    </div>
};
export type ExpandableListItem = {
    title: string | JSX.Element;
    body?: any;
}
export type ListItem = string | JSX.Element | ExpandableListItem;

type ListProps = {
    items: ListItem[];
};

export const List: React.FC<ListProps> = ({ items }) => {
    const [selected, setSelected] = useState<number | null>(null);

    function toggleSelection(index: number): void {
        if (selected === index)
            setSelected(null); // if click on an open item, close it.
        else
            setSelected(index);
    }
    return <Fragment> {
        items.map((item: ListItem, index: number) => {
            const { title, body } = item as ExpandableListItem;
            return <Item key={index} title={title} onClick={(): void => toggleSelection(index)} active={selected === index} body={body} />
        })}
    </Fragment>

};