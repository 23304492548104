import { styled } from "goober"
import { FunctionalComponent, h } from "preact"

export const Quote = styled("div")<{ fontSize?: string }>`
    font-style: italic;
    font-size: ${(props): string => props.fontSize ? props.fontSize : "2.1em"};
    /* @media (min-width: 200ch) {
        font-size: 2.1em;
    } */
    grid-column: 2;
    margin-top: 1.1em;
    margin-bottom: 0.4em;
`

export const MiniQuote = styled(Quote)`
    margin-left: 4ch;
    margin-right: 4ch;
    font-size: 1.6em;
`

export const QuoteAuthor = styled("div")`
    text-align: center;
    margin-top: 0em;
    margin-bottom: 1.7em;
    font-style: italic;
    grid-column: 2;
    font-size: 1.3em;
`

export const Paragraph = styled("p")`
    margin-top: 0.5em;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1.45em;  
    @media (min-width: 120ch) {
        margin: 0.5em 5ch;
    }
`

export const SimpleLink = styled("a")`
    text-decoration: none;
    font-style: normal;
    color: inherit;
    &:hover {
        text-decoration: underline dotted white 1px;
    }
`

export const DimSimpleLink = styled(SimpleLink)`
    color: #8f8f8f;
`

export const DottedSimpleLink = styled("a")`
    text-decoration: none;
    color: inherit;
    text-decoration: underline dotted white 1px;
    line-break: loose;
`

interface LinkProps {
    url: string;
    text?: string;
}
export const DottedExternalLink: FunctionalComponent<LinkProps> = ({url, text}: LinkProps) => 
    <DottedSimpleLink href={url} target="_blank" rel="noopener noreferrer">{text ?? url}</DottedSimpleLink>

export const MiniHeader = styled("h2")`
    margin-bottom: 0.1em;
`

export const MicroHeader = styled("h3")`
    margin-bottom: 0.1em;
    margin-top: 0.3em;
`

export const SquareListItem = styled("li")`
    list-style: square;
    margin: 0.2em;
`

// isn't expandable like the list component in List.tsx
export const SimpleList = styled("ul")`
    margin-top: 0.05em;
    margin-bottom: 0.5em;
`

export const Hidden = styled("div")`
    display: none;
`